.about{
    max-width: var(--content-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}

.aboutMe{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.about .text{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.aboutMe p{
    color: var(--color-3);
}

.aboutMe img{
    height: 400px;
}

@media screen and (max-width: 576px) {
    .aboutMe{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about .text {
        width: 100%;
    }
    .aboutMe img{
        margin-top: 50px;
    }
}

@media screen and (max-width: 320px) {
    .aboutMe img{
        width: var(--content-width);
        height: auto;
    }
}