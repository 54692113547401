.landingMessage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 600px;
    height: 100vh;
    max-width: var(--content-width);
    width:100%;
    margin: 0 auto;
}

.landingMessage .small-heading{
    color: var(--color-2);
    font-size: 1.5rem;
}

.landingMessage .big-heading{
    color: var(--color-3);
    font-size: 4rem;
}

.landingMessage div{
    padding: 30px 0;
}
.landingMessage div a {
    color: var(--color-1);
    text-decoration: none;
    font-size: 1.5rem;
    padding: 20px;
    border: 1px solid var(--color-2);
    background-color: var(--color-2);
    border-radius: 5px;
    transition: 300ms;
}

.landingMessage div a:hover{
    background-color: var(--color-1);
    color: var(--color-2);
}


@media screen and (max-width: 992px) {
    .landingMessage .big-heading{
        font-size: 3.5rem;
    }
    .landingMessage .small-heading{
        color: var(--color-2);
        font-size: 1rem;
    }
    .landingMessage div a{
        font-size: 1.3rem;
        padding: 15px;
    }
}

@media screen and (max-width: 768px) {
    .landingMessage .big-heading{
        font-size: 2.7rem;
    }
    .landingMessage div a{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .landingMessage .big-heading{
        font-size: 2rem;
    }
    .landingMessage div a{
        font-size: 1rem;
        padding: 10px;
    }
}

@media screen and (max-width: 411px) {
    .landingMessage .big-heading{
        font-size: 1.6rem;
    }
}