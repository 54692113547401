.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-3);
    height: 80px;
    margin-top: 50px;
}

.footer span:last-child{
    font-size: 0.9rem;
    opacity: 0.7;
}