@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root{
    --color-1: #0a192f;
    --color-2: #64ffda;
    --color-3: rgb(245,245,245);
    --content-width: 1000px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    transition: 300ms;
}
html, body{
    margin: 0;
    padding: 0;
    background-color: var(--color-1) !important;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h1{
    color: var(--color-2);
    align-self: center;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-2);
    border-left: 1px solid gray;
}

@media screen and (max-width: 1366px) {
    :root{
        --content-width: 900px;
    }
}

@media screen and (max-width: 992px) {
    :root{
        --content-width: 700px;
    }
}

@media screen and (max-width: 768px) {
    :root{
        --content-width: 500px;
    }
}

@media screen and (max-width: 576px) {
    :root{
        --content-width: 380px;
    }
}

@media screen and (max-width: 411px) {
    :root{
        --content-width: 300px;
    }
}

@media screen and (max-width: 320px) {
    :root{
        --content-width: 250px;
    }
}