.projects{
    max-width: var(--content-width);
    width: 100%;
    margin: 0 auto;
    color: var(--color-3);
    display: flex;
    flex-direction: column;
    transition: ease-in-out, 300ms;
    padding-top: 80px;
}
.projects div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.more-projects-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#more-project-button{
    padding: 15px 30px;
    font-size: 1.5rem;
    background-color: var(--color-2);
    border: 1px solid var(--color-2);
    transition: ease-in-out, 300ms;
    color: var(--color-1);
    border-radius: 30px;
}

#more-project-button:hover{
    cursor: pointer;
    background-color: var(--color-1);
    color: var(--color-2);
}

.project-list{
    width: 450px;
    height: 230px;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 40px;
}

.project-list:hover img{
    opacity: 0.6;
}

.project-list:hover ul{
    z-index: 2;
}

.project-list img{
    width: inherit;
    position: absolute;
    transition: 300ms;
    cursor: pointer;
}

.project-list ul{
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
}
.project-list ul li{
    padding: 6px;
    margin: 5px;
    background-color: var(--color-1);
    color: var(--color-2);
    border-radius: 5px;
    transition: 300ms;
    cursor: pointer;
}

.more-projects,
.more-projects div{
    display: flex;
    flex-direction: column;
}

.more-projects div h2{
    color: var(--color-2);
}

.more-projects div div{
    padding-bottom: 30px;
}

.more-projects div div div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.github svg, .website svg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    fill: var(--color-2);
    background-color: var(--color-1);
    border-radius: 50%;
    padding: 2px;
    transition: ease-in-out, 500ms;
    margin: 0 2px;
}

.github svg:hover, .website svg:hover{
    fill: var(--color-1);
    background-color: var(--color-2);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.project-title{
    font-size: 1.3rem;
}

.project-description{
    color: rgba(245,245,245, 0.7)
}

.ReactCollapse--collapse {
    transition: height 500ms;
  }

/* React Modal */
.ReactModal__Overlay{
    z-index: 2;
    top: 0; right: 0; bottom: 0; left: 0; 
    
}

.ReactModal__Body--open{
    height: 100%;
    overflow: hidden;
}

.projectModal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 600px;
    height: 100vh;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.3);
    overflow: auto;
}

.modalChild{
    display: flex;
    flex-direction: column;
    background-color: var(--color-1);
    color: var(--color-3);
    width: 80%;
    overflow-y: auto;
    align-items: center;
    padding-bottom: 20px;
}

.modalChild div{
    display: flex;
}

.modalChild div svg{
    height: 30px;
}

.modalChild button{
    background: none;
    border: none;
    color: var(--color-3);
    font-size: 1.5rem;
    width: 30px;
    align-self: flex-end;
    margin: 10px;
    cursor: pointer;
}

.modalChild img{
    width: 60%;
    cursor: pointer;
}

.modalChild p{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1366px) {
    .project-list{
        width: 400px;
        height: 204px;
    }
}

@media screen and (max-width: 992px) {
    .project-list{
        width: 340px;
        height: 174px;
    }
    .project-list ul li{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .project-list{
        width: 100%;
        height: 255px;
    }
    .project-list ul li{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .project-list{
        height: 204px;
    }
    .project-list ul li{
        font-size: 1.1rem;
    }
    #more-project-button{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 411px) {
    .project-list{
        height: 153px;
    }
    .project-list ul li{
        font-size: 0.8rem;
        padding: 5px;
        margin: 4px;
    }
}

@media screen and (max-width: 320px) {
    .project-list{
        height: 128px;
    }
    .project-list ul li{
        font-size: 0.6rem;
    }
}