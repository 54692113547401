@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

header{
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-1);
    z-index: 1;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 100%;
}
nav li{
    color: var(--color-3);
    text-decoration: none;
    margin-left: 20px;
    font-size: 1.2rem;
    transition: 300ms;
    cursor: pointer;
}
#resume{
    border: 1px solid var(--color-2);
    background-color: var(--color-2);
    border-radius: 5px;
    padding: 8px;
    color: var(--color-1);
    text-decoration: none;
    transition: 300ms
}
#resume:hover{
    background-color: var(--color-1);
    color: var(--color-2);  
}
nav li:hover{
    color: var(--color-2);
}
.icon{
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 1.4rem;
}
.icon a{
    text-decoration: none;
    color: var(--color-3);
    cursor: pointer;
}

.links{
    display: flex;
    align-items: center;
    list-style: none;
}

.navButton{
    display: none;
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 5vw;
}

.burger{
    height: 4px;
    width: 40px;
    background-color: var(--color-2);
    border-radius: 5px;
}
.burger::before,
.burger::after{
    content: '';
    height: 4px;
    width: 40px;
    background-color: var(--color-2);
    border-radius: 5px;
    transition: 300ms ease-in-out;
    position: absolute;
}

.burger::before{
    -webkit-transform: translateY(-14px);
            transform: translateY(-14px);
}

.burger::after{
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
}

@media screen and (max-width: 768px) {
    nav ul{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: -75vw;
        bottom: 0;
        height: 100vh;
        margin: 0;
        padding: 0;
        width: 75vw;
        background-color: #0f2647;
        transition: 300ms;
        justify-content: center;
        align-items: center;
    }

    nav {
        padding: 0 5vw;
    }

    nav ul.active{
        right: 0;
    }

    nav ul li{
        margin-bottom: 3rem;
        letter-spacing: 3px;
    }

    .navButton{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .burger.active::before{
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    .burger.active::after{
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
    }

    .burger.active {
        background-color: transparent;
    }
}

.myContainer{
    max-width: var(--content-width);
    margin: 0 auto;
}
.landingMessage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 600px;
    height: 100vh;
    max-width: var(--content-width);
    width:100%;
    margin: 0 auto;
}

.landingMessage .small-heading{
    color: var(--color-2);
    font-size: 1.5rem;
}

.landingMessage .big-heading{
    color: var(--color-3);
    font-size: 4rem;
}

.landingMessage div{
    padding: 30px 0;
}
.landingMessage div a {
    color: var(--color-1);
    text-decoration: none;
    font-size: 1.5rem;
    padding: 20px;
    border: 1px solid var(--color-2);
    background-color: var(--color-2);
    border-radius: 5px;
    transition: 300ms;
}

.landingMessage div a:hover{
    background-color: var(--color-1);
    color: var(--color-2);
}


@media screen and (max-width: 992px) {
    .landingMessage .big-heading{
        font-size: 3.5rem;
    }
    .landingMessage .small-heading{
        color: var(--color-2);
        font-size: 1rem;
    }
    .landingMessage div a{
        font-size: 1.3rem;
        padding: 15px;
    }
}

@media screen and (max-width: 768px) {
    .landingMessage .big-heading{
        font-size: 2.7rem;
    }
    .landingMessage div a{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .landingMessage .big-heading{
        font-size: 2rem;
    }
    .landingMessage div a{
        font-size: 1rem;
        padding: 10px;
    }
}

@media screen and (max-width: 411px) {
    .landingMessage .big-heading{
        font-size: 1.6rem;
    }
}
.about{
    max-width: var(--content-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}

.aboutMe{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.about .text{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.aboutMe p{
    color: var(--color-3);
}

.aboutMe img{
    height: 400px;
}

@media screen and (max-width: 576px) {
    .aboutMe{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about .text {
        width: 100%;
    }
    .aboutMe img{
        margin-top: 50px;
    }
}

@media screen and (max-width: 320px) {
    .aboutMe img{
        width: var(--content-width);
        height: auto;
    }
}
.skills{
    max-width: var(--content-width);
    width: 100%;
    color: var(--color-3);
    display: flex;
    flex-direction: column;
}

.skills ul{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    height: 80px;
    width: auto;
}

.skills ul li{
    list-style-type: none;
    line-height: 1.5rem;
}

.skills ul li::before{
    content: '\27A3';
    padding-right: 5px;
    color: var(--color-2)
}
.projects{
    max-width: var(--content-width);
    width: 100%;
    margin: 0 auto;
    color: var(--color-3);
    display: flex;
    flex-direction: column;
    transition: ease-in-out, 300ms;
    padding-top: 80px;
}
.projects div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.more-projects-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#more-project-button{
    padding: 15px 30px;
    font-size: 1.5rem;
    background-color: var(--color-2);
    border: 1px solid var(--color-2);
    transition: ease-in-out, 300ms;
    color: var(--color-1);
    border-radius: 30px;
}

#more-project-button:hover{
    cursor: pointer;
    background-color: var(--color-1);
    color: var(--color-2);
}

.project-list{
    width: 450px;
    height: 230px;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 40px;
}

.project-list:hover img{
    opacity: 0.6;
}

.project-list:hover ul{
    z-index: 2;
}

.project-list img{
    width: inherit;
    position: absolute;
    transition: 300ms;
    cursor: pointer;
}

.project-list ul{
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
}
.project-list ul li{
    padding: 6px;
    margin: 5px;
    background-color: var(--color-1);
    color: var(--color-2);
    border-radius: 5px;
    transition: 300ms;
    cursor: pointer;
}

.more-projects,
.more-projects div{
    display: flex;
    flex-direction: column;
}

.more-projects div h2{
    color: var(--color-2);
}

.more-projects div div{
    padding-bottom: 30px;
}

.more-projects div div div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.github svg, .website svg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    fill: var(--color-2);
    background-color: var(--color-1);
    border-radius: 50%;
    padding: 2px;
    transition: ease-in-out, 500ms;
    margin: 0 2px;
}

.github svg:hover, .website svg:hover{
    fill: var(--color-1);
    background-color: var(--color-2);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.project-title{
    font-size: 1.3rem;
}

.project-description{
    color: rgba(245,245,245, 0.7)
}

.ReactCollapse--collapse {
    transition: height 500ms;
  }

/* React Modal */
.ReactModal__Overlay{
    z-index: 2;
    top: 0; right: 0; bottom: 0; left: 0; 
    
}

.ReactModal__Body--open{
    height: 100%;
    overflow: hidden;
}

.projectModal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 600px;
    height: 100vh;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.3);
    overflow: auto;
}

.modalChild{
    display: flex;
    flex-direction: column;
    background-color: var(--color-1);
    color: var(--color-3);
    width: 80%;
    overflow-y: auto;
    align-items: center;
    padding-bottom: 20px;
}

.modalChild div{
    display: flex;
}

.modalChild div svg{
    height: 30px;
}

.modalChild button{
    background: none;
    border: none;
    color: var(--color-3);
    font-size: 1.5rem;
    width: 30px;
    align-self: flex-end;
    margin: 10px;
    cursor: pointer;
}

.modalChild img{
    width: 60%;
    cursor: pointer;
}

.modalChild p{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1366px) {
    .project-list{
        width: 400px;
        height: 204px;
    }
}

@media screen and (max-width: 992px) {
    .project-list{
        width: 340px;
        height: 174px;
    }
    .project-list ul li{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .project-list{
        width: 100%;
        height: 255px;
    }
    .project-list ul li{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .project-list{
        height: 204px;
    }
    .project-list ul li{
        font-size: 1.1rem;
    }
    #more-project-button{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 411px) {
    .project-list{
        height: 153px;
    }
    .project-list ul li{
        font-size: 0.8rem;
        padding: 5px;
        margin: 4px;
    }
}

@media screen and (max-width: 320px) {
    .project-list{
        height: 128px;
    }
    .project-list ul li{
        font-size: 0.6rem;
    }
}
.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-3);
    padding-top: 80px;
}

.contact h1{
    margin-bottom: 0;
}

.contact p{
    font-size: 1.1rem;
}

.contact .message{
    color: var(--color-2);
    text-decoration: none;
    font-size: 1.2rem;
    padding: 20px;
    border: 2px solid var(--color-2);
    border-radius: 5px;
    transition: 300ms;
}

.contact .message:hover{
    background-color: var(--color-2);
    color: var(--color-1);
}

.icon-container{
    display: flex;
    padding-top: 30px;
}

.contact svg{
    fill: var(--color-2);
    transition: 300ms;
    height: 25px;
    width: 25px;
    padding: 0;
}


.contact .icon-github svg{
    height: 30px;
    width: 30px;
}

.contact .icons{
    border-radius: 50%;
    border: 2px solid var(--color-2);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;transition: 300ms;
    height: 30px;
    width: 30px;
    margin: 0 10px;
}

.contact .icons:hover{
    background-color: var(--color-2);
}

.contact .icons:hover svg{
    fill: var(--color-1);
}




@media screen and (max-width: 411px) {
    .contact p {
        font-size: 0.9rem;
        margin-bottom: 20px;
    }

    .contact h1 {
        font-size: 2rem;
    }

    .contact .message {
        font-size: 1rem;
    }
}

@media screen and (max-width: 320px) {

}
.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-3);
    height: 80px;
    margin-top: 50px;
}

.footer span:last-child{
    font-size: 0.9rem;
    opacity: 0.7;
}
:root{
    --color-1: #0a192f;
    --color-2: #64ffda;
    --color-3: rgb(245,245,245);
    --content-width: 1000px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    transition: 300ms;
}
html, body{
    margin: 0;
    padding: 0;
    background-color: #0a192f !important;
    background-color: var(--color-1) !important;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h1{
    color: #64ffda;
    color: var(--color-2);
    align-self: center;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #64ffda;
    background-color: var(--color-2);
    border-left: 1px solid gray;
}

@media screen and (max-width: 1366px) {
    :root{
        --content-width: 900px;
    }
}

@media screen and (max-width: 992px) {
    :root{
        --content-width: 700px;
    }
}

@media screen and (max-width: 768px) {
    :root{
        --content-width: 500px;
    }
}

@media screen and (max-width: 576px) {
    :root{
        --content-width: 380px;
    }
}

@media screen and (max-width: 411px) {
    :root{
        --content-width: 300px;
    }
}

@media screen and (max-width: 320px) {
    :root{
        --content-width: 250px;
    }
}
