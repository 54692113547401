@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap');
header{
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-1);
    z-index: 1;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 100%;
}
nav li{
    color: var(--color-3);
    text-decoration: none;
    margin-left: 20px;
    font-size: 1.2rem;
    transition: 300ms;
    cursor: pointer;
}
#resume{
    border: 1px solid var(--color-2);
    background-color: var(--color-2);
    border-radius: 5px;
    padding: 8px;
    color: var(--color-1);
    text-decoration: none;
    transition: 300ms
}
#resume:hover{
    background-color: var(--color-1);
    color: var(--color-2);  
}
nav li:hover{
    color: var(--color-2);
}
.icon{
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 1.4rem;
}
.icon a{
    text-decoration: none;
    color: var(--color-3);
    cursor: pointer;
}

.links{
    display: flex;
    align-items: center;
    list-style: none;
}

.navButton{
    display: none;
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 5vw;
}

.burger{
    height: 4px;
    width: 40px;
    background-color: var(--color-2);
    border-radius: 5px;
}
.burger::before,
.burger::after{
    content: '';
    height: 4px;
    width: 40px;
    background-color: var(--color-2);
    border-radius: 5px;
    transition: 300ms ease-in-out;
    position: absolute;
}

.burger::before{
    transform: translateY(-14px);
}

.burger::after{
    transform: translateY(14px);
}

@media screen and (max-width: 768px) {
    nav ul{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: -75vw;
        bottom: 0;
        height: 100vh;
        margin: 0;
        padding: 0;
        width: 75vw;
        background-color: #0f2647;
        transition: 300ms;
        justify-content: center;
        align-items: center;
    }

    nav {
        padding: 0 5vw;
    }

    nav ul.active{
        right: 0;
    }

    nav ul li{
        margin-bottom: 3rem;
        letter-spacing: 3px;
    }

    .navButton{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .burger.active::before{
        transform: rotate(-45deg);
    }

    .burger.active::after{
        transform: rotate(45deg);
    }

    .burger.active {
        background-color: transparent;
    }
}
