.skills{
    max-width: var(--content-width);
    width: 100%;
    color: var(--color-3);
    display: flex;
    flex-direction: column;
}

.skills ul{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    height: 80px;
    width: auto;
}

.skills ul li{
    list-style-type: none;
    line-height: 1.5rem;
}

.skills ul li::before{
    content: '\27A3';
    padding-right: 5px;
    color: var(--color-2)
}