.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-3);
    padding-top: 80px;
}

.contact h1{
    margin-bottom: 0;
}

.contact p{
    font-size: 1.1rem;
}

.contact .message{
    color: var(--color-2);
    text-decoration: none;
    font-size: 1.2rem;
    padding: 20px;
    border: 2px solid var(--color-2);
    border-radius: 5px;
    transition: 300ms;
}

.contact .message:hover{
    background-color: var(--color-2);
    color: var(--color-1);
}

.icon-container{
    display: flex;
    padding-top: 30px;
}

.contact svg{
    fill: var(--color-2);
    transition: 300ms;
    height: 25px;
    width: 25px;
    padding: 0;
}


.contact .icon-github svg{
    height: 30px;
    width: 30px;
}

.contact .icons{
    border-radius: 50%;
    border: 2px solid var(--color-2);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;transition: 300ms;
    height: 30px;
    width: 30px;
    margin: 0 10px;
}

.contact .icons:hover{
    background-color: var(--color-2);
}

.contact .icons:hover svg{
    fill: var(--color-1);
}




@media screen and (max-width: 411px) {
    .contact p {
        font-size: 0.9rem;
        margin-bottom: 20px;
    }

    .contact h1 {
        font-size: 2rem;
    }

    .contact .message {
        font-size: 1rem;
    }
}

@media screen and (max-width: 320px) {

}